import React, { useRef, useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import classNames from 'classnames';
import moment from 'moment';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { getMe, userQuerySettings } from '../../../queries/user';
import { getAssignment, getOral, uploadOral, getAssignmentResults } from '../../../queries/student_assignments';
import Navigation from '../../../components/navigation/navigation';
import Button from '../../../components/button/button';
import Loading from '../../../components/loading/loading';
import Countdown from 'react-countdown';
import Webcam from "react-webcam";

const WebcamStreamCapture = ({ assignmentID, oralID }) => {
  const webcamRef = React.useRef(null);
  const mediaRecorderRef = React.useRef(null);
  const queryClient = useQueryClient();
  const history = useHistory();
  const [hasPermission, setHasPermission] = useState(false);
  const [capturing, setCapturing] = React.useState(false);
  const [recordedChunks, setRecordedChunks] = React.useState([]);

  const audioConstraints = {
    echoCancellation: false,
    autoGainControl: false,
    noiseSuppression: false,
    audioBitsPerSecond: 256000,
    audioBitRateMode: "constant",
  }
  const upload = useMutation(uploadOral, {
    onSettled: (data, error, variables, context) => {
      queryClient.invalidateQueries(['student-oral', oralID]);
    },
    onSuccess: (data, variables, context) => {
      history.push(`/my-assignments/${assignmentID}`);
    },
    onError: (data, error, variables, context) => {
      rollbar.error(error, context);
    }
  })

  const handleStartCaptureClick = React.useCallback(() => {
    setCapturing(true);
    mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
      mimeType: "video/mp4",
    });
    mediaRecorderRef.current.addEventListener(
      "dataavailable",
      handleDataAvailable
    );
    mediaRecorderRef.current.start();
  }, [webcamRef, setCapturing, mediaRecorderRef]);

  const handleDataAvailable = React.useCallback(
    ({ data }) => {
      console.log("In handle data")
      if (data.size > 0) {
        setRecordedChunks((prev) => prev.concat(data));
        console.log("Got chunk!", data)
        const blob = new Blob([data], {
          type: "video/mp4"
        });
        console.log("blob")
        console.dir(blob)
        console.log("chunks")
        console.dir(recordedChunks)
        upload.mutate({
          id: oralID,
          videoBlob: blob
        });
      }
    },
    [setRecordedChunks]
  );

  const handleUpload = () => {
    const blob = new Blob(recordedChunks, {
      type: "video/mp4"
    });
    console.log("blob")
    console.dir(blob)
    console.log("chunks")
    console.dir(recordedChunks)
    upload.mutate(oralID, blob);

  }
  const handleStopCaptureClick = React.useCallback(() => {
    mediaRecorderRef.current.stop();
    setCapturing(false);
    //handleUpload();
  }, [recordedChunks]);

  const handleDownload = React.useCallback(() => {
    if (recordedChunks.length) {
      const blob = new Blob(recordedChunks, {
        type: "video/mp4"
      });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      a.href = url;
      a.download = `${oralID}.mp4`;
      a.click();
      window.URL.revokeObjectURL(url);
      setRecordedChunks([]);
    }
  }, [recordedChunks]);


  navigator.permissions.query({ name: 'camera' }).then((result) => {
    if (result.state === "granted") {
      setHasPermission(true);
    }
  })


  return (
    <>
      {!hasPermission && (
        <div className="my-6 w-full p-4 bg-red-100 border border-red-300 text-xl">
          You must grant permission to use your camera and microphone in your browser to record your oral assignment.
          You should see a popup message asking for your permission.
        </div>
      )}
      <div className="w-full flex justify-center">
        <Webcam
          audio={true}
          muted={true}
          ref={webcamRef}
          mirrored={true}
          audioConstraints={audioConstraints}
        />
      </div>
      <div className="mt-8 mb-24 w-full flex justify-center">

        {capturing ? (
          <button
            className="px-4 py-3 rounded text-white bg-red-500 hover:bg-red-700"
            onClick={handleStopCaptureClick}>
            Stop Recording and Submit Oral Assignment
          </button>
        ) : (
          <button
            className="px-4 py-3 rounded text-white bg-green-500 hover:bg-green-700"
            onClick={handleStartCaptureClick}>
            Start Recording
          </button>
        )}
        {/*
        {recordedChunks.length > 0 && (
          <button onClick={handleDownload}>Download</button>
        )} */}
      </div>
    </>
  );
};

export const OralRecord = () => {
  const { id } = useParams();
  const [recording, setRecording] = useState(false);
  const user = useQuery('me', getMe, userQuerySettings);
  const assignment = useQuery(['student-assignment', id], () => getAssignment(id));
  const oral = useQuery(['student-oral', id], () => getOral(id));

  if (assignment.isLoading || user.isLoading || oral.isLoading) {
    return <Loading />;
  }

  const startTime = moment(oral.data.timer_started);

  return (
    <div>
      <Navigation
        history={history}
        title={assignment.data.title}
        backURL={`/my-assignments/${id}`}
        hasBackButton
      />
      <div className="p-4">
        <div className="my-2">
          <h2 className="text-2xl font-bold border border-1 border-grey-400 border-solid p-2 bg-gray-200">
            Record Oral Assignment
          </h2>
        </div>
        <div className="my-4 p-1 border border-1 border-grey-400 bg-gray-100 p-4">
          <p className="my-2">
            Important: As you respond to the questions below it's important to state the number of the question you are answering.
          </p>
          <p className="my-2 mb-6">
            Please say, "Question Number 1" followed by your answer then "Question Number 2" followed by your answer.
          </p>

          <p className="my-2">
            <strong>Question #1</strong> Now that you have seen feedback about your Submission, what is the one thing you would have changed
            about your Submission to improve it?
          </p>
          <p className="my-2">
            <strong>Question #2</strong> How helpful did you find the AI feedback compared to classmates' feedback?
            <br />(Purpose: To evaluate and compare AI-produced feedback vs human feedback and determine what we can learn from both.)
          </p>
        </div>

        <div className="w-full p-4 bg-blue-100 border border-blue-300 text-center mb-8">
          <Countdown
            date={startTime + 10 * 60 * 1000}
            renderer={({ hours, minutes, seconds, completed }) => {
              return (<span className="font-bold text-xl">Time Remaining: {minutes} minutes {seconds} seconds</span>)
            }}
          />
        </div>

        <WebcamStreamCapture
          assignmentID={id}
          oralID={oral.data.id}
        />
      </div>
    </div>
  );
}


export default OralRecord;
