import React, { useState, useEffect } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { Link, useParams, useHistory } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import FadeLoader from "react-spinners/FadeLoader";
import { stateText, nextState } from '../../../common/state';
import { shortDate } from '../../../common/utils';
import { useInterval } from '../../../common/utils';
import { getMe, userQuerySettings } from '../../../queries/user';
import {
  getAssignment, getAssignmentDefaults, getAssignmentSubmissionCounts, getAssignmentFeedbackCounts,
  getAssignmentReviewCounts, togglePause, sendReminderEmail, sendDelayedEmail, transitionToSubmission, transitionToReview,
  transitionToReviewFeedback, transitionToCompletedSkipFeedback, transitionToCompleted, getAssignmentReviewDistribution,
  getAssignmentFeedbackDistribution,
} from '../../../queries/admin-queries';
import Loading from '../../../components/loading/loading';
import Modal from '../../../components/modal/modal';
import Input from '../../../components/input/input';

const buttonClasses = classNames('rounded py-2 px-4 text-lg text-white bg-blue-500 hover:bg-blue-700 w-full cursor-pointer my-1');
const greenButtonClasses = classNames('rounded py-2 px-4 text-lg text-white bg-green-500 hover:bg-green-700 w-full cursor-pointer my-1');

const AdvanceModal = (props) => {
  const { assignment, customCloseHandler } = props;
  const queryClient = useQueryClient();
  const assignmentData = assignment.data;

  const nextStage = nextState(assignmentData);
  const nextText = stateText(nextStage);

  const advanceText = `Advance to ${nextText}`;

  const pClasses = classNames('my-4');

  let thisMutation = null;

  if (assignmentData.state == 'pending') {
    thisMutation = transitionToSubmission;
  } else if (assignmentData.state == 'submission') {
    thisMutation = transitionToReview;
  } else if (assignmentData.state == 'review') {
    if (assignmentData.skip_feedback_state) {
      thisMutation = transitionToCompletedSkipFeedback;
    } else {
      thisMutation = transitionToReviewFeedback;
    }
  } else if (assignmentData.state == 'review_feedback') {
    thisMutation = transitionToCompleted;
  } else if (assignmentData.state == 'adv_completed') {
    thisMutation = transitionToCompleted;
  }

  // Setup mutation
  const transitionMutation = useMutation(thisMutation, {
    onSettled: (data, error, variables, context) => {
      queryClient.invalidateQueries(['admin-assignment', variables.id]);
      queryClient.invalidateQueries(['admin-assignment-submissioncounts', variables.id]);
      queryClient.invalidateQueries(['admin-assignment-reviewcounts', variables.id]);
      queryClient.invalidateQueries(['admin-assignment-feedbackcounts', variables.id]);
      queryClient.invalidateQueries(['admin-assignment-reviewdistribution', variables.id]);
      queryClient.invalidateQueries(['admin-assignment-feedbackdistribution', variables.id]);
      customCloseHandler();
    }
  });

  const onSubmit = (event) => {
    event.preventDefault();
    try {
      transitionMutation.mutate({
        id: assignmentData.id,
      });
    } catch (e) { console.log('error', e); }
  };

  let dueDate = null;
  let nextDescription = <p className={pClasses}>The current state is unknown, so advance is disabled. Please contact technical support.</p>;
  switch (nextStage) {
    case 'submission':
      nextDescription = <p className={pClasses}>Moving to Stage 1: Submission will open up the first stage to the participants.</p>;
      dueDate = assignmentData.publish_date;
      break;
    case 'review':
      nextDescription = (
        <React.Fragment>
          <p className={pClasses}>Moving to Stage 2: Review will generate the Reviews for the participants to complete.  After this Submissions cannot be accepted.</p>
          <p className={pClasses}><strong>This operation cannot be undone.</strong></p>
        </React.Fragment>
      );
      dueDate = assignmentData.due_date;
      break;
    case 'review_feedback':
      nextDescription = (
        <React.Fragment>
          <p className={pClasses}>Moving to Stage 3: Score Review will generate the Score Reviews for the participants to complete.  After this Reviews cannot be accepted.</p>
          <p className={pClasses}><strong>This operation cannot be undone.</strong></p>
        </React.Fragment>
      );
      dueDate = assignmentData.review_due_date;
      break;
    case 'completed':
      nextDescription = (
        <React.Fragment>
          <p className={pClasses}>This ends the assignment. </p>
          <p className={pClasses}><strong>This operation cannot be undone.</strong></p>
        </React.Fragment>
      );
      if (assignmentData.skip_feedback_state) {
        dueDate = assignmentData.review_due_date;
      } else {
        dueDate = assignmentData.review_feedback_due_date;
      }
      break;
    default:
      break;
  }


  // Don't allow faculty to progress stages before the due date
  const compareDate = moment(dueDate).toDate();
  const now = moment();
  let blockProgression = false;

  if (now < compareDate) {
    console.log("Checking due dates");
    console.dir(compareDate);
    console.dir(now);
    blockProgression = true;
    nextDescription = (
      <React.Fragment>
        <p className={pClasses}>Cannot progress to next stage. Deadline has not passed.</p>
        <p className={pClasses}>Cannot progress until {shortDate(dueDate)} </p>
      </React.Fragment>
    )
  }

  try {
    return (
      <Modal
        title={advanceText}
        customCloseHandler={customCloseHandler}
        className="advance-modal"
      >
        <form onSubmit={onSubmit}>
          {nextDescription}
          {!blockProgression && <button type="submit" className={buttonClasses}>{advanceText}</button>}
        </form>
      </Modal>

    );
  } catch (e) { console.dir(e); }
};

const PauseModal = (props) => {
  const { assignment, customCloseHandler } = props;
  const queryClient = useQueryClient();
  const [message, setMessage] = useState(assignment.data.pause_message);

  const togglePauseMutation = useMutation(togglePause, {
    onSettled: (data, error, variables, context) => {
      queryClient.invalidateQueries(['admin-assignment', variables.id]);
      customCloseHandler();
    }
  });

  const onSubmit = (event) => {
    event.preventDefault();
    togglePauseMutation.mutate({
      id: assignment.data.id,
      pause: !assignment.data.pause,
      message,
    });
  };

  let pauseText = 'User access to the Assignment will be paused!';
  let buttonText = 'Pause the Assignment with this message';
  if (assignment.data.paused) {
    pauseText = 'User access to the Assignment will be restored!';
    buttonText = 'Resume Assignment';
  }

  const buttonClasses = classNames('rounded py-2 px-4 text-lg text-white w-full', {
    'bg-green-500': assignment.data.paused === true,
    'hover:bg-green-700': assignment.data.paused === true,
    'bg-red-500': assignment.data.paused === false,
    'hover:bg-red-700': assignment.data.paused === false,
  });

  try {
    return (
      <Modal
        title={pauseText}
        customCloseHandler={customCloseHandler}
      >
        <form onSubmit={onSubmit}>
          {!assignment.data.paused && (
            <React.Fragment>
              <p className="mb-4">Participants in the assignment will see the message: "Class is currently paused"</p>
              <p className="my-4">If you want additional text, please type it below. <strong>NOTE:</strong> Stages will still advance - the pause refers only to user access.</p>
              <Input
                type="textarea"
                onChange={(e) => setMessage(e.target.value)}
                name="message"
                defaultValue={message}
                rows={3}
              />
            </React.Fragment>
          )}
          <button type="submit" className={buttonClasses}>{buttonText}</button>
        </form>
      </Modal>

    );
  } catch (e) { console.dir(e); }
};

const RemindModal = (props) => {
  const { assignment, customCloseHandler } = props;

  const sendMutation = useMutation(sendReminderEmail, {
    onSettled: (data, error, variables, context) => {
      customCloseHandler();
    }
  });

  const onSubmit = (event) => {
    event.preventDefault();
    try {
      sendMutation.mutate({
        id: assignment.data.id,
      });
    } catch (e) { console.log('error', e); }
  };

  const buttonText = 'Send students due date reminder';

  const buttonClasses = classNames('rounded py-2 px-4 text-lg text-white w-full bg-green-500');

  try {
    return (
      <Modal
        title="Send Reminder Email"
        customCloseHandler={customCloseHandler}
      >
        <form onSubmit={onSubmit}>
          <p className="my-4">All students with incomplete work in this stage will receive a single reminder email.</p>
          <button type="submit" className={buttonClasses}>{buttonText}</button>
        </form>
      </Modal>

    );
  } catch (e) { console.dir(e); }
};

const DelaySendModal = (props) => {
  const { assignment, customCloseHandler } = props;
  const queryClient = useQueryClient();

  const sendMutation = useMutation(sendDelayedEmail, {
    onSettled: (data, error, variables, context) => {
      queryClient.invalidateQueries(['admin-assignment', variables.id]);
      customCloseHandler();
    }
  });

  const onSubmit = (event) => {
    event.preventDefault();
    try {
      sendMutation.mutate({
        id: assignment.data.id,
      });
    } catch (e) { console.log('error', e); }
  };

  const buttonText = 'Send students stage change email';

  const buttonClasses = classNames('rounded py-2 px-4 text-lg text-white w-full bg-green-500');

  try {
    return (
      <Modal
        title="Send Stage Change Email"
        customCloseHandler={customCloseHandler}
      >
        <form onSubmit={onSubmit}>
          <p className="my-4">All students will receive an email letting them know the stage has changed.</p>
          <button type="submit" className={buttonClasses}>{buttonText}</button>
        </form>
      </Modal>

    );
  } catch (e) { console.dir(e); }
};

const CurrentState = (props) => {
  // Handle colors
  const classes = classNames('rounded py-2 px-4 text-lg text-white bg-gray-800', {
    'bg-gray-400': !props.paused && props.state === 'pending',
    'bg-orange-500': !props.paused && props.state === 'submission',
    'bg-green-500': !props.paused && props.state === 'review',
    'bg-blue-500': !props.paused && props.state === 'review_feedback',
    'bg-green-500': !props.paused && props.state === 'adv_completed',
    'bg-red-500': !props.paused && props.state === 'completed',
    'bg-yellow-500': props.paused === true,
    'bg-yellow-500': props.published === false,
    'uppercase': props.published === false,
    'text-gray-800': props.published === false,
  });

  const stateTextDisplay = stateText(props.state, props.paused, props.published);

  return (
    <span className={classes}>{stateTextDisplay}</span>
  );
};

const ButtonLink = (props) => (
  <span className={buttonClasses}>
    <Link to={props.url}>{props.children}</Link>
  </span>
);

const GreenButtonLink = (props) => (
  <span className={greenButtonClasses}>
    <Link to={props.url}>{props.children}</Link>
  </span>
);

const PausedAlert = (props) => {
  const { paused, message } = props;
  // We're a no-op if we aren't paused
  if (paused === false) {
    return (null);
  }

  return (
    <div className="rounded bg-yellow-100 p-4 w-full my-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <svg className="h-5 w-5 text-yellow-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
          </svg>
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-yellow-800">
            User Access to Assignment Paused
          </h3>
          <div className="mt-2 text-sm text-yellow-800">
            <p>
              This assignment has been paused.  Students are currently shown the following message:
              {' '}
              <br />
            </p>
          </div>
          <div className="mt-2 text-sm text-yellow-700">
            <p>Class is currently paused</p>
            <p>
              {message}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const NextStage = (props) => {
  const { automated, state, next_due_date } = props;

  if (state === 'completed') {
    return (null);
  }

  let body = "When manually advanced";

  if (automated && next_due_date !== null) {
    body = shortDate(next_due_date);
  }

  return (
    <div className="my-4">
      <p>
        <strong className="text-lg font-bold">Next Stage Begins: </strong>
        {body}
      </p>
    </div>
  );
};

const DistributionTable = (props) => {
  const { labelText, distribution } = props;
  // Return empty if we're not ready yet
  if (distribution.isLoading) {
    return (<React.Fragment></React.Fragment>);
  }
  if (distribution.isError) {
    return (<React.Fragment><p>ERROR LOADING!</p></React.Fragment>);
  }

  return (
    <div className="my-4">
      <h5 className="font-bold">{labelText} Distribution</h5>
      <table className="my-4">
        <thead>
          <tr>
            <th>{labelText}s Assigned</th>
            <th>Participant Count</th>
          </tr>
        </thead>
        <tbody>
          {distribution.data.map((item, index) => {
            return (
              <tr key={index}>
                <td>{item[0]}</td>
                <td>{item[1]}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  )
};

const SubmissionSummary = (props) => {
  const { assignment, submissionCounts } = props;
  if (assignment.data.state === 'pending' || assignment.data.state === 'adv_submission') {
    return (
      <React.Fragment></React.Fragment>
    );
  }

  // Handle team assignments
  let isTeamAssignment = assignment.data.type === 'team'

  let countTable = '';
  if (submissionCounts.isSuccess) {
    let yes_or_unanswered = submissionCounts.data.committed_submissions + submissionCounts.data.commitment_unanswered_submissions;
    let totalSubmissions = submissionCounts.data.total_submissions
    let incompleteSubmissions = submissionCounts.data.incomplete_submissions

    if (isTeamAssignment) {
      totalSubmissions = submissionCounts.data.team_count
      incompleteSubmissions = totalSubmissions - submissionCounts.data.complete_submissions
    }
    countTable = (
      <table className="my-4">
        <tbody>
          <tr>
            <th>Complete Submissions</th>
            <td>{submissionCounts.data.complete_submissions}</td>
            <th>No Submissions</th>
            <td>{incompleteSubmissions}</td>
            <th>Total Possible Submissions</th>
            <td>{totalSubmissions}</td>
          </tr>
          {!isTeamAssignment && (
            <>
              <tr>
                <th colSpan={3}>Answered Yes or Did Not Answer: Likely to participate</th>
                <td colSpan={3} className="text-right">{yes_or_unanswered}</td>
              </tr>
              <tr>
                <th colSpan={3}>Answered No TO THE QUESTION: Likely to participate</th>
                <td colSpan={3} className="text-right">{submissionCounts.data.uncommitted_submissions}</td>
              </tr>
            </>
          )}
          <tr>
            <th colSpan={3}>Advancing to Stage 2: Review</th>
            <td colSpan={3} className="text-right">{submissionCounts.data.total_continuing}</td>
          </tr>
        </tbody>
      </table>
    );
  }
  return (
    <div className="mr-4">
      <h4 className="text-lg font-bold">Submission Summary</h4>
      {countTable}
    </div>
  );
};

const ReviewSummary = (props) => {
  const { assignment, reviewCounts, reviewDistribution } = props;
  const { state } = assignment.data;
  if (state === 'pending' || state.includes('submission') || state === 'adv_review') {
    return (
      <React.Fragment></React.Fragment>
    );
  }
  let countTable = '';
  if (reviewCounts.isSuccess) {
    countTable = (
      <table className="my-4">
        <tbody>
          <tr>
            <th>Complete Reviews</th>
            <td>{reviewCounts.data.complete_reviews}</td>
            <th>Incomplete Reviews</th>
            <td>{reviewCounts.data.incomplete_reviews}</td>
            <th>Total Reviews</th>
            <td>{reviewCounts.data.total_reviews}</td>
          </tr>
        </tbody>
      </table>
    );
  }
  return (
    <div className="mr-4">
      <h4 className="text-lg font-bold">Review Summary</h4>
      {countTable}
      <DistributionTable labelText="Review" distribution={reviewDistribution} />
    </div>
  );
};

const FeedbackSummary = (props) => {
  const { assignment, feedbackCounts, feedbackDistribution } = props;
  const { state } = assignment.data;

  // Don't show if skipping stage 3
  if (assignment.data.skip_feedback_state) {
    return (
      <React.Fragment></React.Fragment>
    );
  }

  // Only show if in certain states
  if (
    state === 'pending' || state.includes('submission') ||
    state === 'review' || state === 'adv_review' ||
    state === 'adv_review_feedback'
  ) {
    return (
      <React.Fragment></React.Fragment>
    );
  }
  let countTable = '';
  if (feedbackCounts.isSuccess) {
    countTable = (
      <table className="my-4">
        <tbody>
          <tr>
            <th>Complete Score Reviews</th>
            <td>{feedbackCounts.data.complete_feedbacks}</td>
            <th>Incomplete Score Reviews</th>
            <td>{feedbackCounts.data.incomplete_feedbacks}</td>
            <th>Total Score Reviews</th>
            <td>{feedbackCounts.data.total_feedbacks}</td>
          </tr>
        </tbody>
      </table>
    );
  }
  return (
    <div className="mr-4">
      <h4 className="text-lg font-bold">Score Review Summary</h4>
      {countTable}
      <DistributionTable labelText="Score Review" distribution={feedbackDistribution} />
    </div>

  );
};

const AdvancementWarning = (props) => {
  const { advancing, advancementCounter, assignment, nextStageText } = props;
  // Don't display if we aren't transitioning to another state
  if (!advancing) {
    return (<React.Fragment></React.Fragment>);
  }

  // Display a spinner at first if we're transitioning
  if (advancing && advancementCounter < 60) {
    return (
      <div className="p-4 mr-4 my-6">
        <FadeLoader
          loading={true}
          aria-label="Loading Spinner"
          color="#0000FF"
        />
        <span className="my-2 font-bold">Advancing to next stage...</span>
      </div>
    );
  }

  return (
    <div className="rounded-md bg-yellow-100 p-4 mr-4 my-6">
      <div className="flex">
        <div className="ml-3">
          <h3 className="text-sm font-bold text-yellow-800">Stage Advancement In Progress</h3>
          <div className="mt-2 text-sm text-yellow-700">
            <p className="my-2">
              The system is currently moving your Assignment to {nextStageText}.  This can take a few
              minutes depending on how many participants are in the Section.
            </p>
            <p className="my-2">If your Assignment does not move to {nextStageText} in 10 minutes please contact support.</p>
          </div>
        </div>
      </div>
    </div>
  )
}

const UnpublishedWarning = (props) => {
  const { assignment } = props;

  // Don't show when published
  if (assignment.active) {
    return (<React.Fragment></React.Fragment>);
  }

  return (
    <div className="rounded-md bg-yellow-100 p-4">
      <div className="flex">
        <div className="ml-3">
          <p className="my-2">Your assignment is <strong>NOT</strong> currently published and is completely inaccessible to all participants.</p>
          <p className="my-2">Edit your Assignment with the button to the right until you are happy with it.</p>
          <div className="my-6"><ButtonLink url={`/assignments/${assignment.id}/publish`}>Publish Assignment</ButtonLink></div>
        </div>
      </div>
    </div>
  )
};

const AdvanceStage1Warning = (props) => {
  const { assignment } = props;

  // Don't show when published or automated
  if (assignment.automated || !assignment.active || assignment.state != 'pending') {
    return (<React.Fragment></React.Fragment>);
  }

  return (
    <div className="rounded-md bg-yellow-100 border border-yellow-400 p-4 mr-4">
      <div className="flex">
        <div className="ml-3">
          <p className="my-2">You need to Advance to Stage 1 in order for students to begin work</p>
        </div>
      </div>
    </div>
  )
};

const AssignmentSummary = (props) => {
  const { id } = useParams();
  const history = useHistory();
  const [showPauseModal, setShowPauseModal] = useState(false);
  const [showAdvanceModal, setShowAdvanceModal] = useState(false);
  const [showRemindModal, setShowRemindModal] = useState(false);
  const [showDelaySendModal, setShowDelaySendModal] = useState(false);
  const [advancementCounter, setAdvancementCounter] = useState(0);

  const queryClient = useQueryClient();
  const user = useQuery('me', getMe, userQuerySettings);
  const assignment = useQuery(['admin-assignment', id], () => getAssignment(id), getAssignmentDefaults);
  const submissionCounts = useQuery(['admin-assignment-submissioncounts', id], () => getAssignmentSubmissionCounts(id), { cacheTime: 9000, staleTime: 9000 });
  const reviewCounts = useQuery(['admin-assignment-reviewcounts', id], () => getAssignmentReviewCounts(id), { cacheTime: 600000, staleTime: 10000 });
  const feedbackCounts = useQuery(['admin-assignment-feedbackcounts', id], () => getAssignmentFeedbackCounts(id), { cacheTime: 600000, staleTime: 10000 });
  const reviewDistribution = useQuery(['admin-assignment-reviewdistribution', id], () => getAssignmentReviewDistribution(id), { cacheTime: 600000, staleTime: 10000 });
  const feedbackDistribution = useQuery(['admin-assignment-feedbackdistribution', id], () => getAssignmentFeedbackDistribution(id), { cacheTime: 600000, staleTime: 10000 });

  if (assignment.isLoading || user.isLoading) {
    return (<Loading />);
  }

  if (assignment.isError) {
    return (<h3>Error</h3>);
  }

  // Clear counts when stage changes
  useEffect(() => {
    queryClient.invalidateQueries(['admin-assignment-submissioncounts']);
    queryClient.invalidateQueries(['admin-assignment-reviewcounts']);
    queryClient.invalidateQueries(['admin-assignment-feedbackcounts']);
    queryClient.invalidateQueries(['admin-assignment-reviewdistribution']);
    queryClient.invalidateQueries(['admin-assignment-feedbackdistribution']);
  }, [assignment.data.state]);

  // Quickly determine if we're in the middle of advancing or not
  let advancing = assignment.data.state.includes('adv_') && assignment.data.state !== "adv_completed";

  // Wether or not we are published
  let published = assignment.data.active;

  // Only items in certain stages should be able to send reminder emails
  let showSendReminderEmail = false;

  if (assignment.data.state !== 'pending' && assignment.data.state !== 'completed') {
    showSendReminderEmail = true;
  }

  // Wether or not we're in a team assignment
  let showManageTeams = false;
  if (assignment.data.type == 'team' && assignment.data.state == 'pending') {
    showManageTeams = true;
  }

  let showViewTeams = false;
  if (assignment.data.type == 'team' && assignment.data.state != 'pending') {
    showViewTeams = true;
  }

  // Handle only showing the advancement warnings after 60 seconds
  useInterval(() => {
    if (advancing) {
      console.log(`Counting: ${advancementCounter}`)
      setAdvancementCounter(advancementCounter + 1);
    }
  }, 1000);

  let showSpinner = advancing && advancementCounter < 60;

  // Only show delayed email send if needed AND the user is hijacking (i.e. Peer Learning staff)
  let showSendStageEmail = false;
  if (user.data.is_hijacked_user && assignment.data.delay_stage_change_emails) {
    const state = assignment.data.state;
    switch (state) {
      case 'submission':
        if (!assignment.data.submission_stage_email_sent) {
          showSendStageEmail = true;
        }
        break;
      case 'review':
        if (!assignment.data.review_stage_email_sent) {
          showSendStageEmail = true;
        }
        break;
      case 'review_feedback':
        if (!assignment.data.review_feedback_stage_email_sent) {
          showSendStageEmail = true;
        }
        break;
      default:
        break;
    }
  }

  const pauseClick = (event) => {
    console.log('Pausing!');
    event.preventDefault();
    setShowPauseModal(!showPauseModal);
  };

  let pauseText = 'Pause User Access';
  if (assignment.data.paused) {
    pauseText = 'Resume User Access';
  }

  if (showPauseModal) {
    return (
      <PauseModal
        assignment={assignment}
        customCloseHandler={setShowPauseModal}
      />
    );
  }

  const nextStage = nextState(assignment.data);
  const nextText = stateText(nextStage);
  let advanceText = `Advance to ${nextText}`;

  if (nextStage === 'completed') {
    advanceText = 'Preview / Release Feedback to Students'
  }

  const advanceClick = (event) => {
    event.preventDefault();

    if (nextStage === 'completed') {
      history.push(`/assignments/${assignment.data.id}/complete`);
    } else {
      setShowAdvanceModal(!showAdvanceModal);
    }
  };

  if (showAdvanceModal) {
    return (
      <AdvanceModal
        assignment={assignment}
        customCloseHandler={setShowAdvanceModal}
      />
    );
  }

  // Let users who are hijacked or assignments that allow faculty to control to show the button
  let adminMenu;
  if (user.data.is_hijacked_user || assignment.data.faculty_has_movement_control) {
    let helpText = null;

    if (user.data.is_hijacked_user && !assignment.data.faculty_has_movement_control) {
      helpText = (
        <p className="text-red-500">
          <strong>NOTE:</strong>
          {' '}
          Movement control is not available to faculty users in this assignment.
        </p>
      );
    }

    if (published) {
      adminMenu = (
        <React.Fragment>
          <span className="group flex w-full my-4"><a onClick={advanceClick} className={buttonClasses}>{advanceText}</a></span>
          {helpText}
        </React.Fragment>
      );
    }
  }

  // If stage is completed, don't show movement control
  if (assignment.data.state === 'completed' || advancing) {
    adminMenu = null;
  }

  const remindClick = (event) => {
    event.preventDefault();
    setShowRemindModal(!showRemindModal);
  };

  if (showRemindModal) {
    try {
      return (
        <RemindModal
          assignment={assignment}
          customCloseHandler={setShowRemindModal}
        />
      );
    } catch (err) { console.dir(err); }
  }

  const delaySendClick = (event) => {
    event.preventDefault();
    setShowDelaySendModal(!showDelaySendModal);
  };

  if (showDelaySendModal) {

    try {
      return (
        <DelaySendModal
          assignment={assignment}
          customCloseHandler={setShowDelaySendModal}
        />
      );
    } catch (err) { console.dir(err); }

  }

  let assignmentName = assignment.data.title;
  if (assignment.data.type == 'team') {
    assignmentName = `Team Assignment: ${assignment.data.title}`;
  }

  try {
    return (
      <React.Fragment>
        <div className="flex flex-row items-center">
          <h1 className="my-4 text-2xl font-bold flex-grow">{assignmentName}</h1>
          <span className="flex-initial mx-2">
            <CurrentState
              state={assignment.data.state}
              paused={assignment.data.paused}
              published={published}
            />
          </span>
        </div>
        <hr className="border-1 border-solid border-black my-2" />
        <div className="flex">
          <div className="w-3/4">
            <div className="mr-4">
              <PausedAlert
                paused={assignment.data.paused}
                message={assignment.data.pause_message}
              />
              <UnpublishedWarning assignment={assignment.data} />
            </div>
            <div>
              {published && !advancing && <NextStage
                automated={assignment.data.automated}
                state={assignment.data.state}
                next_due_date={assignment.data.next_due_date}
                published={published}
              />}
              <AdvanceStage1Warning assignment={assignment.data} />
              <AdvancementWarning
                advancing={advancing}
                advancementCounter={advancementCounter}
                assignment={assignment}
                nextStageText={nextText}
              />
            </div>
            {!advancing &&
              <>
                <SubmissionSummary
                  assignment={assignment}
                  submissionCounts={submissionCounts}
                />
                <ReviewSummary
                  assignment={assignment}
                  reviewCounts={reviewCounts}
                  reviewDistribution={reviewDistribution}
                />
                <FeedbackSummary
                  assignment={assignment}
                  feedbackCounts={feedbackCounts}
                  feedbackDistribution={feedbackDistribution}
                />
              </>
            }
          </div>
          <div className="w-1/4">
            <nav className="space-y-1" aria-label="Sidebar">
              <span className="group flex w-full"><GreenButtonLink url={`/assignments/${assignment.data.id}/edit`}>Create &amp; Edit Assignment</GreenButtonLink></span>
              {published && <span className="group flex w-full"><a onClick={pauseClick} className={buttonClasses}>{pauseText}</a></span>}
              {published && <span className="group flex w-full"><a href={`/api/export/bulk/${assignment.data.id}/`} className={buttonClasses}>Download CSV Reports</a></span>}
              {showSendStageEmail && (<span className="group flex w-full"><a onClick={delaySendClick} className={buttonClasses}>Send Stage Change Emails</a></span>)}
              {showSendReminderEmail && (<span className="group flex w-full"><a onClick={remindClick} className={buttonClasses}>Manually Send Reminder Email</a></span>)}
              {showManageTeams && (<span className="group flex w-full"><ButtonLink url={`/assignments/${assignment.data.id}/teams`}>Manage Teams</ButtonLink></span>)}
              {showViewTeams && (<span className="group flex w-full"><ButtonLink url={`/assignments/${assignment.data.id}/view/teams`}>View Teams</ButtonLink></span>)}
              {adminMenu}
            </nav>
          </div>
        </div>
      </React.Fragment>
    );
  } catch (e) { console.dir(e); }
};

export default AssignmentSummary;
