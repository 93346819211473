
export const stateText = (state, paused, active) => {

  let text = "Unknown"
  switch (state) {
    case "pending":
      text = "Pending"
      break;
    case "adv_submission":
      text = "Advancing to Stage 1: Submission"
      break;
    case "submission":
      text = "Stage 1: Submission"
      break;
    case "adv_review":
      text = "Advancing to Stage 2: Review"
      break;
    case "review":
      text = "Stage 2: Review"
      break;
    case "adv_review_feedback":
      text = "Advancing to Stage 3: Score Review"
      break;
    case "review_feedback":
      text = "Stage 3: Score Review"
      break;
    case "adv_oral":
      text = "Advancing to Stage 4: Oral Assignment"
      break;
    case "oral":
      text = "Stage 4: Oral Assignment"
      break;
    case "adv_completed":
      text = "Ready to Release Feedback"
      break;
    case "completed":
      text = "Completed"
      break;
    default:
      text = "Unknown";
  }

  if (paused) {
    text = "Paused"
  }

  if (active === false) {
    text = "Unpublished"
  }

  return (text);
};

export const nextState = (assignment) => {
  const state = assignment.state;

  let next = "Unknown";

  switch (state) {
    case "pending":
      next = "submission";
      break;
    case "submission":
      next = "review";
      break;
    case "adv_submission":
      next = "review";
      break;
    case "review":
      if (assignment.skip_feedback_state) {
        next = "completed";
      } else {
        next = "review_feedback";
      }
      break;
    case "adv_review":
      if (assignment.skip_feedback_state) {
        next = "completed";
      } else {
        next = "review_feedback";
      }
      break;
    case "review_feedback":
      next = "completed";
    case "adv_completed":
      next = "completed";
      break;
    default:
      break;
  };

  return next;
}
